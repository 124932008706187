import React, { useContext, useState, useEffect, useReducer } from "react";
import {
  Button,
  Card,
  Col,
  Checkbox,
  Input,
  message,
  Radio,
  Row,
  Form,
  TimePicker,
  Select,
  Spin,
} from "antd";
import { GlobalStatesContext } from "../../../common/providers";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import { useActivitiesContext } from "../../../common/providers/Activities/ActivitiesProvider";
import { getPassengerData } from "../../../helpers/PassegerData";
import moment from "moment";
import ApiClient from "../../../helpers/ApiClient";
import VerifyOtpModal from "../../../common/OtpModal/VerifyOtpModal";
// import PaymentGateway from "../../../helpers/PaymentGateway";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import CountryList from "../../../common/CountryList";
import {
  getAllActivitiesPrice,
  getActivitiesPartPaymentDetails,
} from "./activitiesHelper";
import "./Activitiescheckout.scss";
import ActivitiesGrandTotalCard from "./ActivitiesGrandTotalCard";
import queryString from "query-string";
import PayGateway from "../../../helpers/PayGateway";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PanVerification from "../../Hotels/HotelCheckout/PanVerification";

const { Option } = Select;
const { TextArea } = Input;

const oriDateFormat = "YYYY-MM-DD";
const PGTYPE = "PGTYPE";
const PGDISPLAY = "PGDISPLAY";
const PGDATA = "PGDATA";
const initialState = { pgDisplay: false, pgData: {}, pgType: -1 };

function reducer(state, action) {
  switch (action.type) {
    case PGTYPE:
      return { ...state, pgType: action.payload };
    case PGDISPLAY:
      return { ...state, pgDisplay: action.payload };
    case PGDATA:
      return { ...state, pgData: action.payload };
    default:
      return state;
  }
}

const Activitiescheckout = () => {
  let history = useHistory();
  const {
    state: {
      otherData: { ConvFee, promoData, redeemAmount, transactionFee },
      partPayment,
    },
    getPartPayment,
    RemovePromoConvFee,
  } = useContext(GlobalStatesContext);

  const {
    user,
    isLogin: { agent },
  } = useAuthContext();

  const {
    state: { activitiesCheckOutData },
  } = useActivitiesContext();
  const [pgDetails, dispatchPgDetails] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [isPartPayment, setIsPartPayment] = useState(0);
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const [contact_form] = Form.useForm();
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [Hold, setIsHold] = useState(false);
  const [Deposit, setDeposit] = useState(false);
  // const [pgDisplay, setPgDisplay] = useState(false);
  // const [pgData, setPgData] = useState({});
  const [SeletedPayOption, setSeletedPayOption] = useState(false);
  const [contactData, setContactData] = useState({});
  const [isTranferOn, setIsTranferOn] = useState(false);
  const [panVerificationNumber, setPanVerificationNumber] = useState('');

  const loadpassengerData = () => {
    if (user && user?.UserID) {
      getPassengerData(user.UserID).then((data) => {
        if (data.status) {
          contact_form.setFieldsValue({
            firstName: data.Name,
            mobile: data.Mobile,
            email: data.Email,
          });
        }
      });
    }
  };

  useEffect(() => {
    RemovePromoConvFee();
    loadpassengerData();
    getPartPayment(1);
    if ((user?.Hold?.filter(x => x == 4) == 4)) {
      setIsHold(true);
    }
    if ((user?.Deposit?.filter(x => x == 4) == 4)) {
      setDeposit(true)
    }

  }, []);

  useEffect(() => {
    if (partPayment?.isPartPayment) {
      checkValidCategory(partPayment);
    }
  }, [partPayment]);

  const checkValidCategory = (partPayment) => {
    if (
      partPayment?.isPartPayment &&
      partPayment?.categories &&
      partPayment?.categories.length > 0
    ) {
      if (activitiesCheckOutData?.TourDetails?.length > 0) {
        let activitiesCityTourNamesArray =
          activitiesCheckOutData?.TourDetails.map((item) => item.cityTourType);

        let unique = [...new Set(activitiesCityTourNamesArray)];
        let errors = [];
        if (unique?.length > 0) {
          unique.forEach((catName) => {
            if (
              partPayment?.categories.filter(
                (cat) => cat.categoryName == catName
              ).length == 0
            ) {
              errors.push(catName);
            }
          });
        }

        let todayDate = moment();
        activitiesCheckOutData?.TourDetails.forEach((tour) => {
          let cancelDate = moment(tour?.cancellationDate, oriDateFormat);

          if (tour?.cancellationDate) {
            if (
              cancelDate.diff(todayDate, "days") <
              partPayment.MinDaysToEnablePartPay
            ) {
              errors.push(tour.tourId);
            }
          } else {
            errors.push(tour.cityTourType);
          }
        });

        if (errors.length > 0) {
          message.error(
            "Part Payment is unavailable. Please Choose other payment option.",
            5
          );
          setIsPartPayment(1);
          contact_form.setFieldsValue({ paymentOption: 0 });
          return;
        } else {
          setIsPartPayment(2);
        }
      }
    }
  };

  const userReg = (name, email, mobile) => {
    let regObj = {
      Name: name,
      Email: email,
      DialingCode: "+91",
      Mobile: mobile,
      Password: "",
      Role: 4,
      DeviceToken: "string",
      DeviceType: "Web",
      FirBaseToken: "string",
    };

    ApiClient.post("admin/user/register", regObj)
      .then(() => { })
      .catch();
  };

  const handleBookActivity = (contactData, pgType) => {
    if (activitiesCheckOutData?.TourDetails?.length > 0) {
      const { grandTotal, agentMarkup, commision } = getAllActivitiesPrice(
        activitiesCheckOutData?.TourDetails,
        ConvFee,
        promoData,
        redeemAmount,
        SeletedPayOption == true ? transactionFee : 0,
      );

      const { partPaymentAmount, dueAmount } = getActivitiesPartPaymentDetails(
        grandTotal,
        partPayment
      );

      let otherAmount = {
        agentMarkup,
        commision,
      };
      let partPaymentDetails = {
        isPartPayment: false,
        serviceTotal: Number(grandTotal),
        partPaymentAmount: 0,
        dueAmount: 0,
        serviceType: 4,
      };

      if (user) {
        if (user.UserID) {
          if (contactData.paymentOption === 2 && isPartPayment === 2) {
            let partAmount = partPaymentAmount;
            let due = dueAmount;
            partPaymentDetails = {
              isPartPayment: true,
              serviceTotal: Number(grandTotal),
              partPaymentAmount: Number(partAmount),
              dueAmount: Number(due),
              serviceType: 4,
            };
          }
        }
      }

      let reqData = {
        TraceId: activitiesCheckOutData?.TourDetails[0].traceId,
        UserId: user?.UserID ?? 1,
        RoleType: user?.Role?.RoleId ?? 4,
        membership: user?.Membership ?? 1,
        currency: "AED",

        TourDetails: activitiesCheckOutData?.TourDetails.map((item, i) => ({
          tourId: item.tourId,
          apikey: item.apikey,
          tourName: item.tourName,
          cityTourType: item.cityTourType,
          optionId: item.optionId,
          optionName: item.optionName,
          adult: item.adult,
          child: item.child,
          infant: item.infant,
          tourDate: item.tourDate,
          timeSlotId: item.timeSlotId,
          startTime: item.startTime,
          transferId: item.transferId,
          transferName: item.transferName,
          pickup: contactData[`pickup-${i}`] ?? "",
          adultRate: item.adultRate,
          childRate: item.childRate,
          infantRate: item.infantRate,
          serviceTotal: item.serviceTotal,
          cancellationDate: item.cancellationDate,
          tourImage: item.tourImage,
          duration: item.duration,
          tourShortDescription: item.tourShortDescription,
          // commission: item.commission ?? 0,
          markup: item.markup ?? 0,
          agentMarkup: item.agentMarkup ?? 0,
          // adminCommission: item.adminCommission ?? 0,
          traceId: item.traceId,
          // Code by karthik For transfer details
          flightname: contactData[`flightname-${i}`] ?? null,
          flightnumber: contactData[`flightnumber-${i}`] ?? null,
          checkInLuggage: contactData[`checkInLuggage-${i}`] ?? null,
          handluggages: contactData[`handluggages-${i}`] ?? null,
        })),
        PartPayment: partPaymentDetails,
        Passengers: {
          prefix: contactData.prefix,
          firstName: contactData.firstName,
          lastName: contactData.lastName,
          email: contactData.email,
          mobile: contactData.mobile,
          nationality: contactData.nationality,
          message: contactData.message,
          leadPassenger: 1,
          paxType: "adult",
          pan_no: panVerificationNumber,
          specialReq: contactData?.specialReq ?? ''
          // Wrong logic so remove by karthik
          // flightname: contactData.flightname,
          // flightnumber: contactData.flightnumber,
          // checkInLuggage: contactData.checkInLuggage,
          // handluggages: contactData.handluggages,
        },
        promoCode: promoData.Code ?? "",
        convienenceId: ConvFee.id ?? 0,
        pgType: pgType ? pgType : pgDetails.pgType,
        paymentMethod: transactionFee?.methodType,
        isCouponReedem: redeemAmount.status ?? false,
        totalPrice:
          partPaymentDetails.isPartPayment === true
            ? partPaymentDetails.partPaymentAmount
            : partPaymentDetails.serviceTotal,
      };

      if (!user) {
        userReg(contactData.firstName, contactData.email, contactData.mobile);
      }
      CheckAvailability(reqData, contactData, otherAmount);
    }
  };

  const CheckAvailability = (reqData, contactData, otherAmount) => {
    setIsLoading(true);
    setLoadingSpin(true);
    ApiClient.post("activities/tours/availability", reqData)
      .then((result) => result)
      .then((resp) => {
        setIsLoading(false);
        setLoadingSpin(false);

        if (resp.statusCode == 200) {
          if (resp.data.available == true) {
            window.location.href = resp.data.payment_link;
          } else {
            message.error("Tour is unavailable", 10);
          }
        } else if (resp.statusCode == 400) {
          if (
            resp?.error?.length > 0 &&
            activitiesCheckOutData?.TourDetails?.length > 0
          ) {
            let filterOption = activitiesCheckOutData?.TourDetails.filter(
              (item) => item.optionId === resp?.error[0].optionId
            );

            message.error(
              `${filterOption[0]?.optionName !== undefined ? filterOption[0]?.optionName : ''}. 
                            ${resp?.error[0].message}
                          `,
              10
            );
            return;
          }
          message.error("Tour is unavailable", 10);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setLoadingSpin(true);
      });
  };

  // const processPayment = (oid, passanger, partPayment, otherAmount) => {
  //   const amount =
  //     partPayment.isPartPayment === true
  //       ? partPayment.partPaymentAmount
  //       : partPayment.serviceTotal;
  //   let data = {
  //     OrderId: oid,
  //     OrderAmount: parseFloat(amount),
  //     OrderCurrency: "INR",
  //     OrderNote: "Static Note",
  //     CustomerName: `${passanger?.firstName} ${passanger?.lastName}`,
  //     CustomerEmail: passanger?.email,
  //     CustomerPhone: passanger?.mobile,
  //     TDS: 0,
  //     GST: 0,
  //     Commission: Number(otherAmount.commision), // for agent only
  //     AgentMarkup: Number(otherAmount.agentMarkup), // for agent only
  //   };

  //   setPgData(data);
  //   setPgDisplay(true);
  // };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );

  const backToResultPage = () => {
    if (activitiesCheckOutData?.searchRequest) {
      let query = {
        country: activitiesCheckOutData?.searchRequest?.country,
        city: activitiesCheckOutData?.searchRequest?.city,
        travelDate: activitiesCheckOutData?.searchRequest?.travelDate,
      };
      let queryParams = queryString.stringify(query);
      history.push("/activities/results?" + queryParams);
    }
  };

  const backToCartPage = () => {
    history.push("/activities/cart");
  };

  const handleVerifyOtp = (otpValue) => {
    ApiClient.post("admin/verifyserviceOtp", {
      Mobile: contactData?.mobile,
      DialingCode: "+91",
      Otp: Number(otpValue),
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);
          handleBookActivity(contactData, pgDetails.pgType);
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const handleSendOTP = (contactDetails) => {
    ApiClient.post("admin/sendserviceOtp", {
      Mobile: contactDetails?.mobile,
      DialingCode: "+91",
      refUrl: '/activity/checkout'
    })
      .then((res) => {
        if (res?.status === 200) {
          setContactData(contactDetails);
          setVerifyModalVisible(true);
          // dispatchPgDetails({ type: PGDISPLAY, payload: true })
        } else {
          if (res?.message) message.error(res.message, 3);
          else {
            // dispatchPgDetails({ type: PGDISPLAY, payload: false })
            message.error("Booking Failed", 3);
          }
        }
      })
      .catch();
  };

  const ValidateBookingLimit = (contactDetails) => {

    setContactData((prev) => ({ ...prev, ...contactDetails }));
    const { grandTotal } = getAllActivitiesPrice(
      activitiesCheckOutData?.TourDetails,
      ConvFee,
      promoData,
      redeemAmount
    );
    ApiClient.post("admin/validatebookinglimit", {
      serviceType: 4,
      roleType: user?.Role?.RoleId ?? 4,
      bookingAmount: grandTotal ? grandTotal : 0,
    })
      .then((res) => {
        if (res?.status === 200 && res?.isValid) {
          if (transactionFee && transactionFee?.methodType !== undefined) {
            // user?.Role?.RoleId != null ? dispatchPgDetails({ type: PGDISPLAY, payload: true }) : 
            handleSendOTP(contactDetails);
          } else {
            message.error("Please Select Payment Method Type", 3);
            // dispatchPgDetails({ type: PGDISPLAY, payload: false });
          }
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const goTo = () => {
    // contact_form.validateFields().then((contactDetails) => {
    //   ValidateBookingLimit(contactDetails)
    // });
    dispatchPgDetails({ type: PGDISPLAY, payload: true });
  };

  const onChange = (time, timeString, tourId) => {
    activitiesCheckOutData?.TourDetails.map((tour) => {
      if (tour.tourId === tourId) {
        tour.startTime = timeString;
      }
    })
  };

  const blockApiReq = (pgType) => {
    dispatchPgDetails({ type: PGTYPE, payload: pgType });
    if (pgType) {
      contact_form.validateFields().then((contactDetails) => {
        if (agent || user?.Role?.RoleId == 2) {
          handleBookActivity(contactDetails, pgType);
        }else {
          ValidateBookingLimit(contactDetails);
        }
      });
    }
  };

  const OnPaymentModeSelection = (e) => {
    if (e.target.value == 1) {
      setSeletedPayOption(true);
    } else {
      setSeletedPayOption(false);
    }
  };

  // const getTimebyUser = (optionData) => {
  //   if (
  //     (optionData.cityTourType.includes('Airport') ||
  //       optionData.cityTourType.includes('Transfer') &&
  //       optionData.transferName === "Private Transfers")
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const getTimebyUser = (optionData) => {
    if ((optionData.cityTourType.includes('Airport') && optionData.transferName === "Private Transfers"))
      return 1;
    else if ((optionData.cityTourType.includes('Transfer')  && optionData.transferName === "Private Transfers"))
      return 0;
    else
      return -1
  };

  const getUpdatePlaceHolderbyTourName = (tourName, type) => {
    if (type === "PLACEHOLDER") {
      if (tourName.includes ("Pick Up"))
        return "Drop off"
      else if (tourName.includes("Drop Off"))
        return "Pick Up"
      else return "Pick Up"
    } else {
      if (tourName.includes("Pick Up"))
        return "Pick Up"
      else if (tourName.includes("Drop Off"))
        return "Drop off"
      else return "Pick Up"
    }
  }

  useEffect(() => {
    if(activitiesCheckOutData && activitiesCheckOutData.TourDetails && activitiesCheckOutData?.TourDetails.length > 0){
      let transferTypeData = activitiesCheckOutData?.TourDetails.filter(x => x?.cityTourType?.includes('Airport') || x?.cityTourType?.includes('Transfer') && x?.transferName === "Private Transfers");
      if(transferTypeData.length > 0)
        setIsTranferOn(true);
      else
        setIsTranferOn(false);
    }
  }, [activitiesCheckOutData?.TourDetails])

  return (
    <div>
      <div className="promo providerapi">
        <div className="manage-markup-section activites-cheout-nav">
          <div className="admin-container bar-admin-width">
            <div className="d-flex align-items-center">
              {activitiesCheckOutData?.searchRequest && (
                <p
                  className="nextactivity mr-3"
                  onClick={() => backToResultPage()}
                >
                  <i className="fa fa-arrow-left"></i>&nbsp; Back to Results
                  Page
                </p>
              )}

              <p className="nextactivity" onClick={() => backToCartPage()}>
                <i className="fa fa-arrow-left"></i>&nbsp; Back to Cart
              </p>
            </div>
          </div>
        </div>

        <div className="manage-markup-section">
          <div className="admin-container bar-admin-width">
            <Row className="mobile-row-res">
              <Col md={14} xs={24} className="mobile-offset activi-leftbat">
                <Form
                  layout="vertical"
                  form={contact_form}
                  scrollToFirstError={true}
                >
                  <Card className="checkout-activity-card">
                    <div>
                      <h5 className="booking-option-cart">
                        LEAD PASSENGER DETAILS
                      </h5>
                    </div>
                    <Row gutter={(8, 8)} className="form-rules">
                      <Col md={4} xs={24}>
                        <Form.Item
                          label="Title"
                          rules={[{ required: true }]}
                          name="prefix"
                        >
                          <Select placeholder="Mr">
                            <Select.Option value="Mr">Mr</Select.Option>
                            <Select.Option value="MRS">Mrs.</Select.Option>
                            <Select.Option value="Ms">Ms.</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col md={10} xs={24}>
                        <Form.Item
                          label="First Name"
                          rules={[
                            {
                              required: true,
                              message: "Please input your First Name",
                            },
                          ]}
                          name="firstName"
                        >
                          <Input
                            className="doument-name"
                            placeholder="As shown on passport"
                            size={"large"}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={10} xs={24}>
                        <Form.Item
                          label="Last Name"
                          rules={[
                            {
                              required: true,
                              message: "Please input your family Name",
                            },
                          ]}
                          name="lastName"
                        >
                          <Input
                            className="doument-name"
                            placeholder="As shown on passport"
                            size={"large"}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={8} xs={24}>
                        <Form.Item
                          label="Email"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter",
                            },
                          ]}
                          name="email"
                        >
                          <Input
                            className="doument-name"
                            placeholder="Please Enter"
                            size={"large"}
                          />
                        </Form.Item>
                      </Col>

                      {/* <Col md={8} xs={24}>
                        <Form.Item
                          label="Country"
                          rules={[{ required: true }]}
                          name="nationality"
                        >
                          <Select
                            showSearch
                            placeholder="Select Country"
                            style={{ width: "100%" }}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {CountryList.map((item) => (
                              <Option key={item.name} value={item.name}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col> */}

                      <Col md={8} xs={24}>
                        <Form.Item
                          label="Phone Number"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter",
                            },
                          ]}
                          name="mobile"
                        >
                          <Input
                            className="doument-name"
                            placeholder="India (+91)"
                            size={"large"}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={24} xs={24}>
                        <Form.Item name={"specialReq"}>
                          <TextArea rows={4} placeholder="Special Request" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                  
                  <Card className="my-2">
                    <div>
                      <h5 className="booking-option-cart">PAN DETAILS</h5>
                    </div>
                    <PanVerification serviceType={4} setPanVerificationNumber={setPanVerificationNumber}/>
                  </Card>

                  <Card className="details-offer-card checkout-activity-card">
                    <div>
                      <h5 className="booking-option-cart">EXTRA DETAILS</h5>
                    </div>

                    <Row gutter={(8, 8)}>
                      {activitiesCheckOutData?.TourDetails?.length > 0
                        ? activitiesCheckOutData?.TourDetails?.map(
                          (tour, i) => {
                            return (
                              <>
                                {tour?.transferName !== "Without Transfers" && (
                                <Col md={getTimebyUser(tour) != -1 ? 12 : 24} xs={24}>
                                  <Form.Item
                                    label={tour?.tourName}
                                    rules={[
                                      {
                                        required:
                                          tour?.transferName !==
                                            "Without Transfers",
                                              // ? false
                                              // : true,
                                        message: "Please Enter",
                                      },
                                    ]}
                                    name={"pickup-" + i}
                                  >
                                    <Input
                                      className="doument-name"
                                      placeholder={`${getUpdatePlaceHolderbyTourName(tour?.tourName, "PLACEHOLDER")} Location`}
                                      size={"large"}
                                    />
                                  </Form.Item>
                                </Col>
                                )}
                                {getTimebyUser(tour) != -1 && (
                                  <Col md={12} xs={24}>
                                    <Form.Item
                                      label={`${getUpdatePlaceHolderbyTourName(tour?.tourName, "Time")} Time`}
                                      rules={[{
                                        required: true,
                                        message: " Please select Pick up time",
                                      },
                                      ]}
                                      name={"pickupTime-" + i}>
                                      <TimePicker className="TimePicker" onChange={(value, timeString) => onChange(value, timeString, tour.tourId)} defaultOpenValue={dayjs('00:00', 'HH:mm')}  format={'HH:mm'} style={{ height: "fit-content", display: "block" }} />
                                    </Form.Item>
                                  </Col>
                                )}

                                {getTimebyUser(tour) != -1 && (
                                  <>
                                  <Col md={12} xs={24}>
                                    <Form.Item
                                      label={"Flight/Train Name"}
                                      rules={[{
                                          required:true,
                                          message: "Please Enter Flight/Train Name",
                                        },
                                      ]}
                                      name={"flightname-" + i}
                                    >
                                      <Input
                                        className="doument-name"
                                        placeholder={`Ex:- AirIndia, Vistara, Indigo...`}
                                        size={"large"}
                                      />
                                    </Form.Item>
                                </Col>
                                <Col md={12} xs={24}>
                                  <Form.Item
                                    label={"Flight/Train Number"}
                                    rules={[{
                                        required:true,
                                        message: "Please Enter Flight/Train Number",
                                      },
                                    ]}
                                    name={"flightnumber-" + i}
                                  >
                                    <Input
                                      className="doument-name"
                                      placeholder={`Ex:- AI-860, UK-979, 6E2117...`}
                                      size={"large"}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={12} xs={24}>
                                  <Form.Item
                                    label={"Check-In Luggage 23Kg/per Pax"}
                                    rules={[{
                                        required:false,
                                        message: "Please Choose Number of Luggage ",
                                      },
                                    ]}
                                    name={"checkInLuggage-" + i}
                                  >
                                    <Select
                                    defaultValue={0}
                                    //  style={{ width: 200 }}
                                    //  onChange={handleChange}
                                    >
                                      {[...Array(10)].map((_, i) => (
                                        <Option key={i} value={i}>{i}</Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col md={12} xs={24}>
                                  <Form.Item
                                    label={"Hand Luggage 7Kg/Per Pax "}
                                    rules={[{
                                        required:false,
                                        message: "Please Choose Number of Luggage",
                                      },
                                    ]}
                                    name={"handluggages-" + i}
                                  >
                                    <Select
                                    defaultValue={0}
                                    //  style={{ width: 200 }}
                                    //  onChange={handleChange}
                                    >
                                      {[...Array(10)].map((_, i) => (
                                        <Option key={i} value={i}>{i}</Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                </>
                                )}
                              </>
                            );
                          }
                        )
                        : null}

                    </Row>
                    
                    {/* {isTranferOn && (
                    <>
                      <Row gutter={(8, 8)}>
                        
                      </Row>

                      <Row gutter={(8, 8)}>
                        
                      </Row>
                    </>
                    )} */}
                  </Card>

                  {user?.Role?.RoleId == 2 ? (
                    <Card className="details-offer-card checkout-activity-card">
                      <Form.Item
                        label="SELECT SUITABLE OPTION TO PROCEED FURTHER FOR PAYMENT"
                        name="paymentOption"
                        rules={[
                          {
                            required: true,
                            message: "Please Choose Option",
                          },
                        ]}
                      >
                        <Radio.Group size="large" value={isPartPayment}>
                          <Radio
                            value={2}
                            onChange={(e) => {
                              getPartPayment(e.target.value, {
                                BusinessType: user?.Role?.RoleId,
                                ServiceType: 4,
                              });
                            }}
                          >
                            Part Payment
                          </Radio>
                          <Radio
                            value={1}
                            onChange={(e) => {
                              setIsPartPayment(1);
                              getPartPayment(e.target.value);
                            }}
                          >
                            Full Payment
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Card>
                  ) : null}
                </Form>

                <Card className="details-offer-card checkout-activity-card">
                  <Form layout="vertical">
                    <Form.Item>
                      <Checkbox>
                        By Clicking Pay Now You agree that you have read and
                        understood our Terms & Conditions
                      </Checkbox>
                    </Form.Item>

                    {pgDetails.pgDisplay == false ? (
                      <Button
                        className="book-btn"
                        type="primary"
                        onClick={() => {
                          goTo();
                        }}
                      >
                        {isLoading ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          "Book and Pay "
                        )}
                      </Button>
                    ) : (
                      // <PaymentGateway data={pgData} />
                      <PayGateway
                        blockApiReq={blockApiReq}
                        loadingSpin={loadingSpin}
                        Hold={Hold}
                        IsDeposit={Deposit}
                        OnPaymentModeSelection={OnPaymentModeSelection}
                      />
                    )}
                  </Form>
                </Card>
              </Col>

              <Col md={10} xs={24} className="mobile-offset total-card-bar">
                {activitiesCheckOutData?.TourDetails?.length > 0 ? (
                  <ActivitiesGrandTotalCard
                    activitiesCheckOutData={activitiesCheckOutData?.TourDetails}
                    isPartPayment={isPartPayment}
                    SeletedPayOption={SeletedPayOption}
                  />
                ) : null}
              </Col>
            </Row>
          </div>
        </div>
      </div>
      {verifyModalVisible && (
        <VerifyOtpModal
          visible={verifyModalVisible}
          setVerifyModalVisible={setVerifyModalVisible}
          handleVerifyOtp={handleVerifyOtp}
          handleResendOtp={handleSendOTP}
          contactDetails={contactData}
        />
      )}
    </div>
  );
};
export default Activitiescheckout;
