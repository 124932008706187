import {
  DownloadOutlined,
  FileExcelOutlined,
  MailOutlined,
  MessageOutlined,
  PrinterOutlined,
  DollarOutlined,
  FileTextOutlined,
  CloseCircleOutlined,
  WalletOutlined,
  AlipayOutlined ,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Collapse,
} from "antd";
import { Circle, PDFDownloadLink } from "@react-pdf/renderer";
import React, { useContext, useState, useEffect } from "react";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { getPassengerData } from "../../helpers/PassegerData";
import ApiClient from "../../helpers/ApiClient";

// import { downloadTicketPdf } from "../../helpers/TicketUtils";

import ActivitiesDoc from "../PdfDocuments/Activities/ActivitiesDoc";
import BuildPackageDoc from "../PdfDocuments/BuildPackage/BuildPackageDoc";
import ActivitiesInvoiceDoc from "../PdfDocuments/Activities/ActivitiesInvoice";
import BuildPackageInvoiceDoc from "../PdfDocuments/BuildPackage/BuildPackageInvoice";
// import PaymentGateway from "../../helpers/PaymentGateway";
// import { useSytContext } from "../../common/providers/SytProvider";
import PayGateway from "../../helpers/PayGateway";
import { useCurrencyContext } from "../providers/CurrencyProvider";
import PayMentSelection from "../PaymentSelection/PaymentSelection";
import { GlobalStatesContext } from "../providers";

const ActivityTicketSidebar = ({
  getInvoiceData = () => { },
  printTicket = () => { },
  toggleEmailModal = () => { },
  handleCancel = () => { },
  togglePstMrkModal = () => { },
  submitPostMarkup = () => { },
  submitEmailForm = () => { },
  emailModalVisible,
  invoiceData,
  pstMrkModalVisible,
  setWithFare,
  ticketData,
  type,

  // onCancelTicket,
  // getTicketDetails = () => { },
  // cmsFareRules = {},
  // fareRulesResp = null,
  logoImage,
  toggleVoucheredModal = () => { },
  VoucheredConfirm = () => { },
  VoucheredModalVisible,
  voucherData,
  OnPaymentModeSelection = () => { },
  setValue,
  value,
  IsPaymentGatway,
  blob
}) => {
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const {
    state: {
      otherData: { ConvFee, promoData, redeemAmount, transactionFee },
      partPayment,
    },
    getPartPayment,
    RemovePromoConvFee,
  } = useContext(GlobalStatesContext);
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [smsForm] = Form.useForm();
  const [pstMrkForm] = Form.useForm();
  const [emailForm] = Form.useForm();
  const { Panel } = Collapse;
  const [userInvoiceVisible, setUserinvoiceVisible] = useState(false);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [PaymentOptionEnable, setPaymentOptionEnable] = useState(false);
  const [CancelBtnEnable, setCancelBtnEnable] = useState(true);
  const [limitCount, setLimitCount] = useState({
    smsCount: 0,
    emailCount: 0,
  });
  const [pgData, setPgData] = useState({
    data: {},
    visible: false,
  });

  useEffect(() => {
    if(ticketData.TourDetails.length > 0) {
      let paymentEnable = ticketData.TourDetails.filter(x => x.bookingStatus == 3)
      if(paymentEnable.length > 0) {
        setPaymentOptionEnable(true)
      }
      let cancelBtnEnable = ticketData.TourDetails.filter(x => x.bookingStatus == 6 || x.bookingStatus == 2);
      if(cancelBtnEnable.length == ticketData.TourDetails.length) {
        setCancelBtnEnable(false)
      }
    }
  }, []);


  const blockApiReq = (pgType) => {
    setLoadingSpin(true)
    let reqData = {
      TraceId: ticketData?.TraceId,
      UserId: ticketData?.UserId ?? 1,
      RoleType: ticketData?.RoleType ?? 4,
      membership: user?.Membership ?? 1,
      currency: "AED",
      TourDetails: ticketData?.TourDetails.filter(x => x.bookingStatus === 3 || x.bookingStatus === 7).map((item, i) => ({
        tourId: item.tourId,
        apikey: item.apikey,
        tourName: item.tourName,
        cityTourType: item.serviceType,
        optionId: item.optionId,
        optionName: item.optionName,
        adult: item.pax.adults,
        child: item.pax.childCount,
        infant: item.pax.infantCount,
        tourDate: item.journeyDate,
        timeSlotId: item.timeSlotId,
        startTime: item.startTime,
        transferId: item.transferId,
        transferName: item.transferName,
        adultRate: item.adultRate,
        childRate: item.childRate,
        infantRate: item.infantRate,
        serviceTotal: item.bookingStatus === 3 ||  item.bookingStatus === 7 ? item.serviceTotal : 0,
        cancellationDate: item.cancellationDate,
        tourImage: item.tourImage,
        duration: item.duration,
        tourShortDescription: item.tourShortDescription,
        markup: item.markup ?? 0,
        agentMarkup: item.agentMarkup ?? 0,
      })),
      PartPayment: ticketData.PartPayment,
      Passengers: ticketData.Passengers,
      promoCode: promoData.Code ?? "",
      convienenceId: ConvFee.id ?? 0,
      pgType: pgType,
      commission: ticketData.commission ?? 0,
      agentMarkup: ticketData.agentMarkup ?? 0,
      paymentMethod: transactionFee?.methodType,
      isCouponReedem: redeemAmount.status ?? false,
      totalPrice: ticketData.PartPayment.isPartPayment === true ? ticketData.PartPayment.partPaymentAmount : ticketData?.TourDetails.filter(item => item.bookingStatus === 3 || item.bookingStatus === 7).reduce((total, item) => total + item.serviceTotal, transactionFee.amount ?? 0),
      ReferenceNumber: ticketData?.TourDetails[0].referenceNumber
    };
    ApiClient.post(`activities/tours/vourcherTicket/`, reqData)
      .then((res) => {
        setLoadingSpin(false)
        if (res.statusCode === 200) {
          window.location.href = res.data.payment_link;
        } else {
          message.error("Payment Failed", 4);
        }
      })
      .catch(() => { });
  }

  return (
    <div className="actionable-buttons">
      {type === "Activities" && CancelBtnEnable ?
        <div className="mb-3">
          <Collapse
            accordion
            expandIcon={() => <DownloadOutlined style={{ color: '#3d96ee' }} />}
            expandIconPosition="right"
          >
            <Panel
              header={
                <span style={{ color: '#3d96ee' }}>
                  {agent && ticketData.TourDetails[0]?.transferName === "Private Transfers"
                    ? "Download Transfer Voucher"
                    : "Download Vouchered"}
                </span>
              }
              key="1"
            >
              <div className="trip-type-selector">
                <div className="trip-type-selector">
                  {type === "Activities" ? (
                    <PDFDownloadLink
                      document={
                        <ActivitiesDoc
                          ticketData={ticketData}
                          withFare={true}
                          agent={agent}
                          Logo={logoImage}
                          voucherData={voucherData}
                          activeCurrency={activeCurrency}
                          currencyValue={currencyValue}
                        />
                      }
                      // fileName="activitiesTicket.pdf"
                      fileName={
                        ticketData.TourDetails[0].transferName === "Private Transfers"
                          ? "Transfer Ticket.pdf"
                          : "Activities Tour Ticket.pdf"
                      }
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <Button>Loading document... </Button>
                        ) : (
                          <Button className="mb-1">
                            {agent ? "Download Ticket (With Fare)" : "With Fare"}
                          </Button>
                        )
                      }
                    </PDFDownloadLink>
                  ) : null}
                  {type === "Activities" ? (
                    <PDFDownloadLink
                      document={
                        <ActivitiesDoc
                          ticketData={ticketData}
                          withFare={false}
                          agent={agent}
                          Logo={logoImage}
                          voucherData={voucherData}
                        />
                      }
                      // fileName="activitiesTicket.pdf"
                      fileName={
                        ticketData.TourDetails[0].transferName === "Private Transfers"
                          ? "Transfer Ticket.pdf"
                          : "Activities Tour Ticket.pdf"
                      }
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <Button>Loading document... </Button>
                        ) : (
                          <Button>
                            {" "}
                            {agent ? "Download Ticket (Without Fare)" : "Without Fare"}
                          </Button>
                        )
                      }
                    </PDFDownloadLink>
                  ) : null}

                  {type === "BuildPackage" ? (
                    <PDFDownloadLink
                      document={
                        <BuildPackageDoc
                          ticketData={ticketData}
                          withFare={true}
                          agent={agent}
                          Logo={logoImage}
                        />
                      }
                      fileName="PackageTicket.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <Button>Loading document... </Button>
                        ) : (
                          <Button className="mb-1">
                            {agent ? "E-Ticket (With Fare)" : "With Fare"}
                          </Button>
                        )
                      }
                    </PDFDownloadLink>
                  ) : null}
                  {type === "BuildPackage" ? (
                    <PDFDownloadLink
                      document={
                        <BuildPackageDoc
                          ticketData={ticketData}
                          withFare={false}
                          agent={agent}
                          Logo={logoImage}
                        />
                      }
                      fileName="PackageTicket.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <Button>Loading document... </Button>
                        ) : (
                          <Button>
                            {" "}
                            {agent ? "E-Ticket (Without Fare)" : "Without Fare"}
                          </Button>
                        )
                      }
                    </PDFDownloadLink>
                  ) : null}
                  {type === "BuildPackage" && agent ? (
                    <PDFDownloadLink
                      document={
                        <BuildPackageInvoiceDoc
                          invoiceData={invoiceData}
                          logo={logoImage}
                        />
                      }
                      fileName="Package_Invoice.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <Button>Loading document... </Button>
                        ) : (
                          <Button className="invoice-btn">Invoice</Button>
                        )
                      }
                    </PDFDownloadLink>
                  ) : null}
                </div>
              </div>
            </Panel>
          </Collapse>
        </div>
        : null}

      {type === "Activities" && (ticketData.TourDetails[0]?.bookingStatus == 11 || ticketData.TourDetails[0]?.bookingStatus == 3) ?
        <>
          {/* <div className="ticket-options">
            <p onClick={() => printTicket()}>
              <PrinterOutlined /> Print E-Ticket
            </p>  /// remove this section by nitin 
          </div> */}

          <div className="mb-3" style={{backgroundColor:'#f1f8ff'}}>
            <Collapse accordion expandIcon={() => <MailOutlined style={{ color: '#3d96ee' }} />}
              expandIconPosition="right">
              <Panel header={
                <span style={{ color: '#3d96ee' }}>
                 {ticketData.TourDetails[0].bookingStatus == 11
                  ? "Email Vouchered"
                  : "Email Ticket "}
                </span>
              } key="1">
                <div className="trip-type-selector">
                  <div className="trip-type-selector">
                    <Button
                      block
                      style={{ marginBottom: 10 }}
                      onClick={() => {
                        toggleEmailModal();

                        setWithFare(1);
                      }}
                    >
                     Email With Price
                    </Button>

                    <Button
                      block
                      onClick={() => {
                        toggleEmailModal();
                        setWithFare(0);
                      }}
                    >
                     Email Without Price
                    </Button>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>
        </> : null
      }
      {type === "Activities" && CancelBtnEnable
        ? ticketData.cancellable && (
          <div className="ticket-options" style={{backgroundColor:"#ffdbdb"}}>
            <p onClick={() => handleCancel(type)} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#ef6d71', marginRight: '5px', paddingLeft: '15px' }}>
              {ticketData.TourDetails[0].transferName === "Private Transfers" ? "Cancel Transfer" : "Cancel Activity"}
              <CloseCircleOutlined />
            </p>
          </div>
        ):(
          <div className="ticket-options" style={{backgroundColor:"#ffdbdb"}}>
            <p onClick={() => handleCancel(type)} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#ef6d71', marginRight: '5px', paddingLeft: '15px' }}>
              {ticketData.TourDetails[0].transferName === "Private Transfers" ? "Cancel  Vouchered" : "Cancel Activity"}
              <CloseCircleOutlined />
            </p>
          </div>
        )
        }

      {type === "BuildPackage"
        ? ticketData.cancellable && (
          <div className="ticket-options">
            <p onClick={() => handleCancel(type)}>
              <FileExcelOutlined /> Cancel E-Ticket
            </p>
          </div>
        )
        : null}
      {/* Add Code By Karthik 28/April/2023 */}
      {/* {type === "Activities" && ticketData.TourDetails[0]?.bookingStatus == 3 ?
        <>
          <div className="ticket-options">
            <p onClick={() => toggleVoucheredModal()}>
              <DollarOutlined /> Vouchered
            </p>
          </div>
        </>
        : null} */}

      {type === "Activities" && PaymentOptionEnable && (
        <div className="ticket-options">
          {pgData.visible ? (
            <PayGateway blockApiReq={blockApiReq} loadingSpin={loadingSpin} OnPaymentModeSelection={OnPaymentModeSelection} />
          ) : (
            <p onClick={() => setPgData({ visible: true })} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#EE4B2B', marginRight: '5px', paddingLeft: '15px' }}>
               Generate Vouchered <DollarOutlined />
            </p>
          )}
        </div>
      )}

      {type === "Activities" && PaymentOptionEnable && IsPaymentGatway && (
        <div className="mb-3">
          <PayMentSelection setValue={setValue} value={value} />
        </div>
      )}

      {invoiceData && invoiceData?.TourDetails && invoiceData?.TourDetails?.length > 0 ? (agent ? (
        type === "Activities" ? (
          <div className="ticket-options" style={{backgroundColor: '#f1f8ff'}}>
            <p>
              <PDFDownloadLink
                document={
                  <ActivitiesInvoiceDoc
                    invoiceData={invoiceData}
                    logo={logoImage}
                    type={"invoice"}
                  />
                }
                fileName="Activities_Invoice.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <Button>Loading document... </Button>
                  ) : (
                    <Button className="invoice-btn border-0">
                      <strong style={{ color: '#3d96ee' }}>Invoice</strong>
                      <FileTextOutlined style={{ marginLeft: '140px', color: '#3d96ee' }} />
                    </Button>
                  )
                }
              </PDFDownloadLink>
            </p>
          </div>
        ) : null
      ) : null
      ) : null}
      {/* Add Code By Karthik 28/April/2023 */}

      {/* Code Changes By karthik Refence By nitin sir */}
      {agent ? (
        <>
          {/* {ticketData.cancellable ? ( */}

          <div className="ticket-options" style={{backgroundColor: '#f1f8ff'}}>
            <p onClick={() => togglePstMrkModal()} style={{ backgroundColor: '#f1f8ff', color: '#2f8fed', display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '15px', fontWeight: 'bold' }}>
              Add Markup <AlipayOutlined style={{ marginRight: '15px' }} />
            </p>
          </div>
          {/* ) : null} */}
          {/* Code Changes By karthik Refence By nitin sir */}
          <Modal
            title={[
              <div>
                <h6 style={{ marginBottom: "0px" }}>
                  <strong>Add Markup</strong>
                </h6>
              </div>,
            ]}
            width={250}
            className="promo-modal-header"
            visible={pstMrkModalVisible}
            onOk={togglePstMrkModal}
            onCancel={togglePstMrkModal}
            footer={[
              <div>
                <Button key="close" onClick={togglePstMrkModal}>
                  Cancel
                </Button>

                <Button
                  key="add"
                  type="primary"
                  htmlType="submit"
                  onClick={pstMrkForm.submit}
                >
                  Add
                </Button>
              </div>,
            ]}
          >
            <Form
              form={pstMrkForm}
              layout="vertical"
              onFinish={submitPostMarkup}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Amount"
                    name="amount"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },

                      {
                        validator: (_, value) => {
                          if (Number(value) > 0) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject("Please Enter valid Amount");
                          }
                        },
                      },
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} type="number" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
          {/* Add Modal by Karthik  */}
          <Modal
            title={[
              <div>
                <h6 style={{ marginBottom: "0px" }}>
                  <strong>Vouchered</strong>
                </h6>
              </div>,
            ]}
            width={400}
            className="promo-modal-header"
            visible={VoucheredModalVisible}
            onOk={VoucheredConfirm}
            onCancel={toggleVoucheredModal}
            footer={[
              <div>
                <Button key="close" onClick={() => toggleVoucheredModal()}>
                  Cancel
                </Button>
                <Button key="add" type="primary" onClick={VoucheredConfirm}>
                  Confirm
                </Button>
              </div>,
            ]}
          >
            <h6 className="text-center"><strong>Note :- </strong>This Ticket will be Non Refundable and You will get this ticket in 30 Minutes</h6>
          </Modal>

          {/* add email Modal */}
          <Modal
            title={[
              <div>
                <h6 style={{ marginBottom: "0px" }}>
                  <strong>Enter The Email Address</strong>
                </h6>
              </div>,
            ]}
            className="promo-modal-header"
            visible={emailModalVisible}
            onOk={toggleEmailModal}
            onCancel={toggleEmailModal}
            footer={[
              <div>
                <Button key="close" onClick={toggleEmailModal}>
                  Cancel
                </Button>

                <Button
                  key="add"
                  type="primary"
                  htmlType="submit"
                  onClick={emailForm.submit}
                >
                  Send
                </Button>
              </div>,
            ]}
          >
            <Form form={emailForm} layout="vertical" onFinish={submitEmailForm}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Email"
                    name="Email"
                    rules={[
                      { required: true, message: "Required!" },
                      { type: "email", message: "Email is not a valid email" },
                    ]}
                  >
                    <Input placeholder="Enter The Email Address" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        </>
      ) : null}
    </div>
  );
};
export default ActivityTicketSidebar;
