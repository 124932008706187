import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  Col,
  Comment,
  Form,
  Input,
  message,
  Row,
} from "antd";
import moment from "moment";
import "./Bloginfo.scss";
import APIClient from "../../helpers/ApiClient";
import ReactHtmlParser from "react-html-parser";
import NoResultFound from "../ErrorPages/NoResultFound";
import ApiClient from "../../helpers/ApiClient";
import SeoMetaData from "../../common/Seo/SeoMetaData";
import { useLayoutEffect } from "react";
const BASE = process.env.REACT_APP_BASE_URL;

const Bloginfo = ({ match: { params } }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [blog, setBlog] = useState([]);
  console.log(blog);
  const [blogComments, setBlogComments] = useState([]);
  const { TextArea } = Input;
  const [seoMetaData, setSeoMetaData] = useState([])

  useEffect(() => {
    if (params.id) {
      getBlog(Number(params.id));
      getBlogComments(Number(params.id));
    }
  }, []);

  useLayoutEffect(() => {
    getSeoMataData();
  }, [])

  const getBlog = (id) => {
    setIsLoading(true);
    APIClient.get("admin/blog/" + id)
      .then((res) => {
        if (res.status == 200) {
          setBlog([res.data]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setBlog([]);
        setIsLoading(false);
        console.error(error);
      });
  };

  const getBlogComments = (id) => {
    APIClient.get("admin/blogcomments")
      .then((res) => {
        if (res.statusCode == 200) {
          let data = res.data.filter((item) => item.BlogID === Number(id));
          setBlogComments(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const submitForm = (values) => {
    let formData = {
      BlogID: Number(params.id),
      Comment: values.Comment,
      Name: values.Name,
      Email: values.Email,
      Website: values.Website,
      ShowComment: 1,
      CreatedBy: 0,
      ModifiedBy: 0,
      DeviceOS: 3,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      ApplicationType: 1,
      FirBaseToken: "string",
    };

    APIClient.post("admin/blogcomments", formData).then((res) => {
      if (res.statusCode == 200) {
        message.success("Blog comment added successfully", 3);
        getBlogComments(Number(params.id));

        form.resetFields();
      } else {
        message.error(" Error Uploading Data", 3);
      }
    });
  };

  const getSeoMataData = () => {
    ApiClient.get('admin/seodata').then((res) => {
      if(res.statusCode == 200) {
        let path = window.location.pathname;
        let seoRes = res.data.filter(x => x.Path.includes(path));
        setSeoMetaData(seoRes)
      }
    }).catch((e) => {
      console.log(e);
    })
  }

  return (
    <React.Fragment>
      {seoMetaData.length > 0 && <SeoMetaData title={seoMetaData[0].PageTitle} description={seoMetaData[0].PageMetaDesc} keywords={seoMetaData[0].PageMetaKeyword}/>}
    <div className="deals-section">
      {!isLoading ? (
        blog.length > 0 ? (
          <>
            <div
              className="blog-info-bg"
              style={{
                backgroundImage: `url(${
                  BASE + blog[0].BlogTitleBigImage1.substring(1)
                })`,
              }}
            >
              <div className="container deals-top-content">
                <div
                  className="bg-info-heading"
                  style={{ paddingTop: "20px", height: "30%" }}
                >
                  <div
                    className="sec-width-blog"
                    style={{
                      background: "#0000009c",
                      height: "100%",
                      paddingTop: "15px",
                    }}
                  >
                    <h3 className="blog-description">
                      <b>
                        {/* Finally, long awaited destinations - where to go in 2021? */}
                        {blog[0].BlogTitle}
                      </b>
                    </h3>

                    <ul className="trips-blog-sec">
                      <li>
                        {moment(blog[0].PostedDate).format("DD MMMM YYYY")}|
                      </li>
                      <li>
                        {/* IN #GUIDES AND TIPS, #SEE & DO */}
                        {blog[0].Tags} |
                      </li>
                      <li>BY {blog[0].PostByName} |</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="container deals-top-content">
              <Row>
                {/* <Col md={24} xs={24}>
              <ul className="blog-info-social">
                <li>
                  <i className="fa fa-facebook" aria-hidden="true"></i>
                </li>
                <li>
                  <i className="fa fa-twitter" aria-hidden="true"></i>
                </li>
                <li>
                  <i className="fa fa-pinterest" aria-hidden="true"></i>
                </li>
                <li>
                  <i className="fa fa-linkedin" aria-hidden="true"></i>
                </li>
              </ul>

              <p className="text-blog-info">
                Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make a type
                specimen book. It has survived not only five centuries, but
                also the leap into electronic typesetting, remaining
                essentially unchanged. It was popularised in the 1960s with
                the release of Letraset sheets containing Lorem Ipsum
                passages, and more recently with desktop publishing software
                like Aldus PageMaker including versions of Lorem Ipsum.
              </p>

              <p className="para-info-sec">
                Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make a type
                specimen book. It has survived not only five centuries,{" "}
              </p>
            </Col> */}

                <Col md={24} xs={24} className="second-col-sec">
                  <h3> {blog[0].BlogTitle}</h3>
                  {/* <p className="para-info-sec">
                Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make a type
                specimen book. It has survived not only five centuries,{" "}
              </p>
              <p className="para-info-sec">
                Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make a type
                specimen book. It has survived not only five centuries,{" "}
              </p> */}
                </Col>

                <Col md={24} xs={24} className="second-col-sec">
                  <img
                    className="blog-info-img-sec"
                    src={BASE + blog[0].BlogTitleImage1.substring(1)}
                    alt="First slide"
                  />

                  <p className="text-blog-info">
                    {/* Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make a type
                specimen book. It has survived not only five centuries,Lorem
                Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a
                galley of type and scrambled it to make a type specimen book.
                It has survived not only five centuries,{" "} */}
                    {ReactHtmlParser(blog[0].Description)}
                  </p>
                  {/* <p className="para-info-sec">
                Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make a type
                specimen book. It has survived not only five centuries,{" "}
              </p> */}

                  <img
                    className="blog-info-img-sec-1"
                    src={BASE + blog[0].BlogTitleImage2.substring(1)}
                    alt="First slide"
                  />
                </Col>
                {/* 
            <Col md={24} xs={24} className="bd-top-btm">
              <h4 className="offers-sec">
                <i className="fa fa-star-o" aria-hidden="true"></i>&nbsp; Special
                offers on flights and hotels to Greece
              </h4>

              <div className="btn-f-h">
                <Button className="btn-flights-wdh">
                  <i className="fa fa-plane"></i>&nbsp;CHECK FLIGHTS
                </Button>
                &nbsp;
                <Button className="btn-hotel-wdh">
                  <i className="fa fa-bed" aria-hidden="true"></i>&nbsp;CHECK
                  HOTELS
                </Button>
              </div>
            </Col> */}

                {/* <Col md={24} xs={24} className="second-col-sec">
              <p className="para-info-sec">
                Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make a type
                specimen book. It has survived not only five centuries,Lorem
                Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a
                galley of type and scrambled it to make a type specimen book.
                It has survived not only five centuries,{" "}
              </p>
              <p className="para-info-sec">
                Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make a type
                specimen book. It has survived not only five centuries,{" "}
              </p>

              <img
                className="blog-info-img-sec-1"
                src={require("../../assets/images/blog-info-3.jpg")}
                alt="First slide"
              />
            </Col>

            <Col md={24} xs={24} className="bd-top-btm">
              <h4 className="offers-sec">
                <i className="fa fa-star-o" aria-hidden="true"></i>&nbsp; Special
                offers on flights and hotels to Greece
              </h4>

              <div className="btn-f-h">
                <Button className="btn-flights-wdh">
                  <i className="fa fa-plane"></i>&nbsp;CHECK FLIGHTS
                </Button>
                &nbsp;
                <Button className="btn-hotel-wdh">
                  <i className="fa fa-bed" aria-hidden="true"></i>&nbsp;CHECK
                  HOTELS
                </Button>
              </div>
            </Col> */}
              </Row>
              <section className="comment_wrapper">
                <Card>
                  <h4>Comments : </h4>
                  {blogComments.length > 0 ? (
                    blogComments.map((item) => {
                      return (
                        <div className="comments_area">
                          <Comment
                            // actions={actions}
                            author={item.Name}
                            avatar={
                              <Avatar
                                style={{
                                  backgroundColor: "#f56a00",
                                  verticalAlign: "middle",
                                }}
                                size="large"
                              >
                                {item.Name.substring(0, 1).toUpperCase()}
                              </Avatar>
                            }
                            content={<p>{item.Comment}</p>}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <h4>No Comments</h4>
                  )}
                </Card>
              </section>

              <Form form={form} layout="vertical" onFinish={submitForm}>
                <Row className="second-col-sec">
                  <Col md={24} xs={24}>
                    <Form.Item
                      label="ADD COMMENT"
                      name="Comment"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>

                  <Col md={24} xs={24}>
                    <Form.Item
                      label="Name"
                      name="Name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col md={24} xs={24}>
                    <Form.Item
                      label="Email"
                      name="Email"
                      rules={[
                        {
                          required: true,
                        },
                        { type: "email", message: "Email is not valid email" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col md={24} xs={24}>
                    <Form.Item
                      label="Website"
                      name="Website"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col md={24} xs={24}>
                    <Form.Item>
                      <Button htmlType="submit">POST COMMENT</Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </>
        ) : (
          <NoResultFound />
        )
      ) : null}
    </div>
    </React.Fragment>
  );
};

export default Bloginfo;
