import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Table,
} from "antd";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";

import {
  EditOutlined,
  PlusCircleFilled,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

const { Content } = Layout;
const { Option } = Select;
const SeoData = (props) => {
  const [modalVisible, setModalVisible] = useState(false);

  const [dataSource, setDataSource] = useState([]);
  const [blogList, setBogList] = useState([]);

  const [showSearchBox, setShowSearchBox] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [seoDataId, setseoDataId] = useState(null);
  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 23 },
  };

  const showModal = () => {
    if (isEdit) {
      setIsEdit(false);
      form.resetFields();
    }
    setModalVisible({
      visible: true,
    });
  };

  const editSeoData = (record) => {
    setIsEdit(true);
    setseoDataId(record.SeoID);

    form.setFieldsValue({
      Path: record.ScreenName,
      PageTitle: record.PageTitle,
      PageMetaKeyword: record.PageMetaKeyword,
      PageMetaDesc: record.PageMetaDesc,
    });

    setModalVisible(true);
  };

  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => {
              editSeoData(rec);
            }}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => {
              deleteSeoData(rec.SeoID);
            }}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "Sno",
    },

    {
      title: "Screen Name",
      dataIndex: "Path",
    },
    {
      title: "Page Title",
      dataIndex: "PageTitle",
    },
    {
      title: "Page Meta Keyword",
      dataIndex: "PageMetaKeyword",
    },
    {
      title: "Page Meta Desc",
      dataIndex: "PageMetaDesc",
    },

    {
      title: "Actions",
      render: (currentDeal) => tableActions(currentDeal),
    },
  ];

  const selectCustomFilter = (input, option) => {
    let istext =
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    if (istext) {
      return true;
    }
  };

  /*==========Api Calls=====*/

  useEffect(() => {
    getSeoData();
  }, []);

  const getSeoData = () => {
    ApiClient.get("admin/seodata")
      .then((res) => {
        if (res.statusCode == 200) {
          const data = res.data.map((item, index) => {
            return {
              ...item,
              Sno: index + 1,
            };
          });
          setDataSource(data);
        } else {
          message("Server Error");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteSeoData = (id) => {
    ApiClient.delete("admin/blogcomments/" + id)
      .then((res) => {
        if (res.statusCode == 200) {
          message.success("Seo Data Deleted  successfully", 3);
          getSeoData();
        } else {
          message.error("Error Deleting", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const submitForm = (values) => {
    let formData = {
      Path: values.ScreenName,
      PageTitle: values.PageTitle,
      PageMetaKeyword: values.PageMetaKeyword,
      PageMetaDesc: values.PageMetaDesc,
      CreatedBy: 0,
      ModifiedBy: 0,
      DeviceOS: 3,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      ApplicationType: 1,
      FirBaseToken: "string",
    };

    isEdit ? putSeoData(formData) : postSeoData(formData);
  };

  const putSeoData = (formData) => {
    ApiClient.put("admin/seodata/" + seoDataId, {}, formData).then((res) => {
      if (res.status == 200) {
        message.success("Seo Data Updated  successfully", 3);
        setModalVisible(false);
        form.resetFields();
        getSeoData();
      } else {
        message.error(" Error Uploading Data", 3);
      }
    });
  };

  const postSeoData = (formData) => {
    ApiClient.post("admin/seodata", formData).then((res) => {
      if (res.statusCode == 200) {
        message.success("Seo Data added successfully", 3);
        getSeoData();
        setModalVisible(false);
        form.resetFields();
      } else {
        message.error(" Error Uploading Data", 3);
      }
    });
  };

  /*======= Search and Export Options=====*/

  const searchData = (searchData) => {
    setShowSearchBox(true);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = dataSource.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setDataSource(searchResults);
    } else {
      getSeoData();
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const handleExcel = (e) => {
    const excelCustomData = dataSource;

    props.exportExcel(excelCustomData, "dealsExcel");
  };

  return (
    <div>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>View Seo</h5>
                        <p>{dataSource.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData(dataSource)}
                      />
                      <img src={excel} alt="excel" onClick={handleExcel} />
                      <img
                        src={exchange}
                        alt="exchange"
                        onClick={() => getSeoData()}
                      />
                      <p className="add-deposit-icon" onClick={showModal}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>
                  <div>
                    <Table
                      className="table-scroll-none"
                      bordered
                      dataSource={dataSource}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title={isEdit ? "Update Seo Data" : "Add Seo Data"}
        className="promo-modal-header"
        visible={modalVisible}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={form.submit}>
            {isEdit ? "Update" : "Add"}
          </Button>,
        ]}
      >
        <Form
          form={form}
          {...formItemLayout}
          layout="vertical"
          onFinish={submitForm}
        >
          <Form.Item
            label="Screen Name"
            name="ScreenName"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Page Title"
            name="PageTitle"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Page Meta Keyword"
            name="PageMetaKeyword"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.TextArea rows={5} />
          </Form.Item>

          <Form.Item
            label="Page Meta Desc"
            name="PageMetaDesc"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
export default UpdatedComponent(SeoData);
