import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import { getAllActivitiesPrice } from "../../../pages/Activitespkg/Activitiescheckout/activitiesHelper";
import LogoImg from "../../../assets/images/logos/trip-logo.png";

const getQRImage = (index) => {
  let imgUrl = document.getElementById("packageQR" + index);
  if (imgUrl) {
    imgUrl = imgUrl.toDataURL();
    return imgUrl;
  }
};

async function convertImageToBase64(imageUrl, width, height) {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const img = await createImageBitmap(blob);

    // Create a canvas element
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = width;
    canvas.height = height;

    // Draw the image on the canvas with the new dimensions
    ctx.drawImage(img, 0, 0, width, height);

    // Convert canvas to Base64
    return canvas.toDataURL();
  } catch (error) {
    console.error("Error processing image:", error);
  }
}

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    padding: 20,
  },
  section: {
    padding: "0 10px",
  },
  innerSection: {
    position: "relative",
    paddingLeft: 30,
  },
  borderleft: {
    position: "absolute",
    width: 3,
    left: 0,
    height: "100%",
    backgroundColor: "#e9ecef",
  },
  title: {
    fontSize: 12,
    marginBottom: 5,
    color: "#274581",
  },
  subtitle: {
    fontSize: 10,
    marginBottom: 5,
  },
  stitle: {
    fontSize: 10,
  },
  textDanger: {
    color: "#dc3545",
  },

  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
    flexWrap: "wrap",
  },

  wHalf: {
    width: "50%",
    marginBottom: 5,
  },
  card: {
    position: "relative",
    padding: 10,
    border: "1px solid #e9ecef",
    borderRadius: 2,
  },
  tourImg: {
    width: 100,
  },
  mb10: {
    marginBottom: 10,
  },
  contentWrapper: {
    position: "relative",
    marginBottom: 10,
    backgroundColor: "#e3f2fd",
  },

  timelineBadge: {
    position: "absolute",
    top: 9,
    left: -46,
    zIndex: 10,
    width: 35,
    height: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    textAlign: "center",
    fontSize: 9,
    color: "#fff",
    backgroundColor: "#ffbc34",
  },
  description: { width: "100%", fontSize: 10, marginBottom: 8 },
  headerWrraper: {
    backgroundColor: "#e3f2fd",
    padding: "5px 15px",
  },
  mb_5: { marginBottom: 5 },
  addressDetails: {
    textAlign: "right",
    fontSize: 10,
    paddingBottom: 5,
  },
  flex5: {
    flex: 0.49,
  },
  logoSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  textRight: {
    textAlign: "cen",
  },
  alignSelf: {
    alignSelf: "flex-end",
  },
  justifyContentBetween: {
    justifyContent: "space-between",
  },
  wHalfBar: {
    width: "50%",
    marginBottom: 5,
  },
  tourImgBar: {
    width: 100,
    height: "100%",
  },
  requestText: {
    color: "#FF0000",
    textAlign: "left", // 'justify' is not supported for Text components in React Native
  },
});

const getStatus = (status) => {
  switch (status) {
    case "BLOCKED": {
      return <Text style={{ color: "#FFA500" }}>BLOCKED </Text>;
    }
    case "SUCCESS": {
      return <Text style={{ color: "#008000" }}>{status}</Text>;
    }

    case "CONFIRMED": {
      return <Text style={{ color: "#008000" }}>{status}</Text>;
    }

    case "ALREADYCANCELLED": {
      return <Text style={{ color: "#FF0000" }}>ALREADY CANCELLED</Text>;
    }
    case "CANCELLED": {
      return <Text style={{ color: "#FF0000" }}>{status}</Text>;
    }
    case "VOUCHERED": {
      return <Text style={{ color: "#FF0000" }}>{status}</Text>;
    }
    default:
      return;
  }
};
const CancellationDescription = ({ tourShortDescription }) => {
  let returnContentConst = <Text style={{ display: "inline" }}>{}</Text>;
  if (tourShortDescription) {
    const parsedHtml = ReactHtmlParser(tourShortDescription);
    parsedHtml.forEach((element) => {
      if (element?.props && element?.props?.children) {
        element.props.children.forEach((content) => {
          returnContentConst = (
            <Text style={{ display: "inline", color: "red" }}>{content}</Text>
          );
        });
      } else {
        if (element)
          return (
            <Text style={{ display: "inline", color: "red" }}>{element}</Text>
          );
      }
    });
    return returnContentConst;
  }
};
const Description = ({ tourShortDescription }) => {
  let returnContentConst = <Text style={{ display: "inline" }}>{""}</Text>;
  if (tourShortDescription) {
    const parsedHtml = ReactHtmlParser(tourShortDescription);
    parsedHtml.forEach((element) => {
      if (element?.props && element?.props?.children) {
        element.props.children.forEach((content) => {
          returnContentConst = (
            <Text style={{ display: "inline" }}>{content}</Text>
          );
        });
      } else {
        if (element)
          returnContentConst = (
            <Text style={{ display: "inline" }}>{element}</Text>
          );
      }
    });
    return returnContentConst;
  } else {
    return returnContentConst;
  }
};

const getPaxCount = (pax) => {
  return `Adult(s) ${pax?.adults !== "0" ? pax?.adults : 0} ${
    pax?.childCount !== "0" ? ", Child(s) " + pax?.childCount : " "
  } ${pax?.infantCount !== "0" ? ", Infant(s) " + pax?.infantCount : " "}`;
};

const ActivitiesDoc = ({
  ticketData,
  withFare,
  agent,
  Logo,
  voucherData,
  activeCurrency,
  currencyValue = () => {},
}) => {
  const { totalAmount, discount } = getAllActivitiesPrice(
    ticketData.TourDetails,
    ticketData.convienenceData,
    ticketData.promoData
  );
  ticketData.TourDetails = ticketData.TourDetails.filter(
    (x) => x.BookingStatus !== "NOT-AVAILABLE"
  );
  const BASE = process.env.REACT_APP_BASE_URL;
  const getTotalAmount = () => {
    let postMarkup = ticketData.totalPostMarkup
      ? ticketData.totalPostMarkup
      : 0;
    let totalAmount =
      Number(ticketData.PartPayment.serviceTotal) + Number(postMarkup);

    return Number(totalAmount).toFixed(2);
  };

  const getPassengerDetails = () => {
    return (
      <View style={styles.headerWrraper}>
        <Text style={styles.title}>Passenger Details</Text>
        <View style={styles.row}>
          <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
            Guest Name: {ticketData?.Passengers.firstName}{" "}
            {ticketData?.Passengers.lastName}
          </Text>
          <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
            Email: {ticketData?.Passengers.email}
          </Text>
          <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
            Contact: {ticketData?.Passengers.mobile}
          </Text>
          {/* <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
            Pan Card No: {ticketData?.Passengers.pan_no}
          </Text> */}
        </View>
      </View>
    );
  };

  const getTourinfo = () => {
    return (
      <View style={{ ...styles.headerWrraper, ...styles.mb_5 }}>
        {ticketData.TourDetails?.[0].apikey === "EXT" ||
        ticketData.TourDetails?.[0].transferName === "Private Transfer" ? (
          <Text style={styles.title}>Transfer Details</Text>
        ) : (
          <Text style={styles.title}>Tour Details</Text>
        )}
        <View style={styles.row}>
          <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
            {/* Reference Number:{" "} */}
            {(ticketData.TourDetails?.[0].apikey === "EXT" ||
              ticketData.TourDetails?.[0].transferName ===
                "Private Transfer") &&
            ticketData.TourDetails?.[0].BookingStatus === "VOUCHERED"
              ? "Confirmation Number:"
              : "Reference Number:"}
            {ticketData?.TourDetails?.[0].referenceNumber}
          </Text>
          {!agent && (
            <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
              Provider Ref No: {ticketData?.TourDetails?.[0].ProviderRefNo}
            </Text>
          )}

          {ticketData?.TourDetails?.[0].confirmationNo != "" && (
            <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
              Confirmation No: {ticketData?.TourDetails?.[0].confirmationNo}
            </Text>
          )}

          {withFare ? (
            <>
              <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                Payment Mode:{" "}
                {ticketData.PartPayment.isPartPayment === true
                  ? " Part"
                  : " Full"}{" "}
                Payment
              </Text>
              {ticketData?.convienenceData?.amount != "" && (
                <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                  Convienence Fee: {activeCurrency}{" "}
                  {currencyValue(
                    Number(ticketData?.convienenceData?.amount ?? 0).toFixed(2)
                  )}
                </Text>
              )}
              <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                Total Amount: {activeCurrency} {currencyValue(totalAmount)}
              </Text>

              {discount != "" && (
                <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                  Discount: - {activeCurrency}{" "}
                  {isNaN(discount) ? 0 : Number(discount).toFixed(2)}
                </Text>
              )}

              <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                Grand Total: {activeCurrency} {currencyValue(getTotalAmount())}
              </Text>
              {ticketData.PartPayment.isPartPayment === true ? (
                <>
                  <Text style={{ ...styles.subtitle, ...styles.wHalf }}>
                    Paid Amount: {activeCurrency}{" "}
                    {currencyValue(ticketData.PartPayment.partPaymentAmount)}
                  </Text>
                  <Text
                    style={{
                      ...styles.subtitle,
                      ...styles.wHalf,
                      ...styles.textDanger,
                    }}
                  >
                    Due Amount: {activeCurrency}{" "}
                    {currencyValue(ticketData.PartPayment.dueAmount)}
                  </Text>
                </>
              ) : null}
            </>
          ) : null}
        </View>
      </View>
    );
  };

  const getTourDetails = (tour, index, ticketIndex = null, adults, childs) => {
    return (
      <View
        key={index + tour.tourName}
        style={styles.contentWrapper}
        wrap={false}
      >
        <View style={styles.timelineBadge}>
          <Text>
            {moment(tour.journeyDate, "YYYY-MM-DD").format("DD MMM")}{" "}
          </Text>
        </View>

        <View style={styles.card} wrap={false}>
          <Text style={styles.title}>{tour.tourName}</Text>
          <Text
            style={{
              marginLeft: "50%",
              marginTop: -16,
              fontSize: 10,
              marginBottom: 5,
            }}
          >
            {moment(tour.journeyDate, "YYYY-MM-DD").format("DD MMM YYYY")}
          </Text>
          <Text style={styles.subtitle}>{tour.optionName}</Text>
          {/* <Text style={styles.subtitle}>
            Tour Date :{" "}
            {moment(tour.journeyDate, "YYYY-MM-DD").format("ddd DD MMM YYYY")}
          </Text> */}
          <View
            style={{
              ...styles.mb10,
              ...styles.row,
            }}
          >
            {/* {{ height: 100, width: 100, marginBottom: 10 }} */}
            <Text style={{ ...styles.stitle, ...styles.wHalfBar }}>
              <Image
                style={{ height: 100, width: 100, marginBottom: 10 }}
                src={convertImageToBase64(tour.tourImage, 100, 100).then(
                  (base64String) => {
                    return base64String;
                  }
                )}
                allowDangerousPaths
              />
            </Text>
            {ticketIndex != null ? (
              <Text style={{ ...styles.stitle, ...styles.wHalfBar }}>
                <Image
                  allowDangerousPaths
                  src={getQRImage(index + "" + ticketIndex)}
                  style={{ height: 100, width: 100, marginBottom: 10 }}
                />
              </Text>
            ) : null}
          </View>
          {/* <Image style={styles.tourImg} src={tour.tourImage} /> */}
          <View style={styles.description}>
            {tour.apikey !== "EXT" &&
              tour.transferName !== "Private Transfer" && (
                <Description tourShortDescription={tour.tourShortDescription} />
              )}
          </View>
          <View style={styles.row}>
            <Text style={{ ...styles.stitle, ...styles.wHalf }}>
              Booking Status :
            </Text>
            <Text style={{ ...styles.stitle, ...styles.wHalf }}>
              {voucherData === true ? (
                getStatus(tour.BookingStatus)
              ) : (
                <Text style={{ color: "red" }}>VOUCHERED</Text>
              )}
            </Text>
            <Text style={{ ...styles.stitle, ...styles.wHalf }}>
              Tour Date :
            </Text>
            <Text style={{ ...styles.stitle, ...styles.wHalf }}>
              {moment(tour.journeyDate, "YYYY-MM-DD").format("ddd DD MMM YYYY")}
            </Text>
            {(tour.bookingStatus === 11 || tour.bookingStatus === 7) &&
            tour.transferName === "Private Transfers" &&
            tour.apikey === "EXT" ? (
              <>
                <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                  Last Cancellation Date :
                </Text>
                <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                  {moment(tour.journeyDate, "YYYY-MM-DD")
                    .subtract(4, "days")
                    .format("ddd DD MMM YYYY")}
                </Text>
              </>
            ) : null}
            <Text style={{ ...styles.stitle, ...styles.wHalf }}>
              Transfer Option :
            </Text>
            <Text style={{ ...styles.stitle, ...styles.wHalf }}>
              {tour.transferName}
            </Text>
            {tour.duration != "" && tour.apikey !== "EXT" && tour.transferName !== "Private Transfer" && (
              <>
                <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                  Duration :
                </Text>
                <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                  {tour.duration}
                </Text>
              </>
            )}
            <Text style={{ ...styles.stitle, ...styles.wHalf }}>
              {/* Start Time : */}
              {tour.apikey === "EXT" || tour.transferName === "Private Transfer"
                ? "Pickup Time:"
                : "Start Time:"}
            </Text>
            <Text style={{ ...styles.stitle, ...styles.wHalf }}>
              {tour ? tour.startTime : "Time Not Available"}
              {/* {(tour.apikey === "EXT" || tour.transferName === "Private Transfer") ? (tour.pickDropTime || "Time Not Available") : (tour.startTime || "Time Not Available")} */}
            </Text>
            {(adults > 0 || childs > 0) && (
              <>
                <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                  Number of Passengers :
                </Text>
                <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                  {/* {getPaxCount(tour.pax)} remove by nitin sir 06-feb-2024*/}
                  {adults > 0 || childs > 0
                    ? `${adults} Adults ${childs} Childs`
                    : 0}
                </Text>
              </>
            )}

            {tour.pickup != "" && (
              <>
                <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                  {tour.apikey === "EXT" ||
                  tour.transferName === "Private Transfer"
                    ? "Pick/Drop Location:"
                    : "Pickup Location:"}
                </Text>
                <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                  {tour.pickup}
                </Text>
              </>
            )}
            {!(
              tour?.TransferObj == undefined ||
              Object.keys(tour?.TransferObj).length == 0
            ) &&
              tour?.TransferObj?.FlightName !== null &&
              tour?.TransferObj?.FlightNo !== null && (
                <>
                  <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                    Check-In Luggage :
                  </Text>
                  <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                    23 Kg/per * {tour?.TransferObj?.CheckLuggages} Pax
                  </Text>
                  <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                    Hand Luggage :
                  </Text>
                  <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                    7 Kg/per * {tour?.TransferObj?.Handluggage} Pax
                  </Text>
                  <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                    Flight/Train Name :
                  </Text>
                  <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                    {tour?.TransferObj?.FlightName}
                  </Text>
                  <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                    Flight/Train Number :
                  </Text>
                  <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                    {tour?.TransferObj?.FlightNo}
                  </Text>
                </>
              )}
            {withFare ? (
              <>
                <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                  Amount :
                </Text>
                <Text style={{ ...styles.stitle, ...styles.wHalf }}>
                  {activeCurrency}{" "}
                  {currencyValue(tour.serviceTotal?.toFixed(2))}
                </Text>
              </>
            ) : null}
          </View>
          {!(
            tour.apikey === "EXT" || tour.transferName === "Private Transfer"
          ) && (
            <View style={styles.description}>
              <Text style={styles.title}>Cancellation Policy</Text>
              <Text
                style={{
                  display: "inline",
                  color: "red",
                  textAlign: "justify",
                }}
              >
                {tour.ticketPolicy[0]?.cancellationPolicyDescription?.replace(
                  /<[^>]*>?/gm,
                  ""
                )}
              </Text>
            </View>
          )}
        </View>
      </View>
    );
  };

  const getShortAddress = (add) => {
    let addressArr = add.split(" ");
    let Text = "";
    for (let i = 0; i < addressArr.length; i += 3) {
      Text += addressArr[i] + " ";
    }
    return Text;
  };

  const generateTicketHTML = () => {
    return ticketData.TourDetails.map((tourDetail, index) => (
      <>
        {Object.hasOwnProperty(tourDetail?.ticket) === false &&
        tourDetail?.ticket?.length > 0 ? (
          tourDetail.ticket.map((ticketIndex, i) => (
            <Page size="A4" style={styles.page} wrap={true}>
              <View style={styles.section}>
                <View style={{ ...styles.logoSection }}>
                  <View style={{ ...styles.flex5 }}>
                    {Logo ? (
                      <Image
                        src={BASE + Logo.substring(1)}
                        style={{ height: 50, width: 100, marginBottom: 10 }}
                      ></Image>
                    ) : (
                      <Image style={{ width: 100, heigh: 50 }} src={LogoImg} />
                    )}
                  </View>

                  <View
                    style={{
                      ...styles.flex5,
                      ...styles.addressDetails,
                    }}
                  >
                    {ticketData?.agentDetails ? (
                      <View
                        style={{
                          ...styles.textRight,
                          ...styles.justifyContentBetween,
                        }}
                      >
                        <Text style={{ ...styles.alignSelf }}>
                          {ticketData?.agentDetails?.AgencyName}{" "}
                        </Text>
                        <Text style={{ ...styles.alignSelf }}>
                          {ticketData?.agentDetails?.Address1}{" "}
                        </Text>
                        <Text style={{ ...styles.alignSelf }}>
                          {/* {ticketData?.agentDetails?.Address2}{" "} */}
                          {getShortAddress(ticketData?.agentDetails?.Address2)}
                        </Text>
                        {ticketData?.agentDetails?.City ||
                        ticketData?.agentDetails?.State ? (
                          <Text style={{ ...styles.alignSelf }}>
                            {ticketData?.agentDetails?.City}{" "}
                            {ticketData?.agentDetails?.State}
                          </Text>
                        ) : null}

                        <Text style={{ ...styles.alignSelf }}>
                          Email: {ticketData?.agentDetails?.Email}
                        </Text>
                        {ticketData?.agentDetails?.Phone ? (
                          <Text style={{ ...styles.alignSelf }}>
                            Contact No.: {ticketData?.agentDetails?.Phone}
                          </Text>
                        ) : null}
                      </View>
                    ) : ticketData?.adminDetails?.length > 0 ? (
                      <View
                        style={{
                          ...styles.textRight,
                          ...styles.justifyContentBetween,
                        }}
                      >
                        <Text style={{ ...styles.alignSelf }}>
                          {ticketData?.adminDetails[0]?.Address}{" "}
                        </Text>
                        <Text style={{ ...styles.alignSelf }}>
                          {ticketData?.adminDetails[0]?.CityName}{" "}
                          {ticketData?.adminDetails[0]?.StateName}
                        </Text>
                        <Text style={{ ...styles.alignSelf }}>
                          {ticketData?.adminDetails[0]?.CountryName}{" "}
                          {ticketData?.adminDetails[0]?.PostalCode}
                        </Text>
                      </View>
                    ) : null}
                  </View>
                </View>
                <View key={ticketIndex} style={styles.innerSection}>
                  <View style={styles.borderleft}></View>
                  {getPassengerDetails()}
                  {getTourinfo()}
                  {getTourDetails(
                    tourDetail,
                    index,
                    i,
                    ticketIndex.noOfAdult,
                    ticketIndex.noOfchild
                  )}
                </View>
              </View>
            </Page>
          ))
        ) : (
          <Page size="A4" style={styles.page} wrap={true}>
            <View style={styles.section}>
              <View style={{ ...styles.logoSection }}>
                <View style={{ ...styles.flex5 }}>
                  {Logo ? (
                    <Image
                      src={BASE + Logo.substring(1)}
                      style={{ height: 50, width: 100, marginBottom: 10 }}
                    ></Image>
                  ) : (
                    <Image style={{ width: 100, heigh: 50 }} src={LogoImg} />
                  )}
                </View>

                <View
                  style={{
                    ...styles.flex5,
                    ...styles.addressDetails,
                  }}
                >
                  {ticketData?.agentDetails ? (
                    <View
                      style={{
                        ...styles.textRight,
                        ...styles.justifyContentBetween,
                      }}
                    >
                      <Text style={{ ...styles.alignSelf }}>
                        {ticketData?.agentDetails?.AgencyName}{" "}
                      </Text>
                      <Text style={{ ...styles.alignSelf }}>
                        {ticketData?.agentDetails?.Address1}{" "}
                      </Text>
                      <Text style={{ ...styles.alignSelf }}>
                        {ticketData?.agentDetails?.Address2}
                      </Text>
                      {ticketData?.agentDetails?.City ||
                      ticketData?.agentDetails?.State ? (
                        <Text style={{ ...styles.alignSelf }}>
                          {ticketData?.agentDetails?.City}{" "}
                          {ticketData?.agentDetails?.State}
                        </Text>
                      ) : null}

                      <Text style={{ ...styles.alignSelf }}>
                        Email: {ticketData?.agentDetails?.Email}
                      </Text>
                      {ticketData?.agentDetails?.Phone ? (
                        <Text style={{ ...styles.alignSelf }}>
                          Contact No.: {ticketData?.agentDetails?.Phone}
                        </Text>
                      ) : null}
                    </View>
                  ) : ticketData?.adminDetails?.length > 0 ? (
                    <View
                      style={{
                        ...styles.textRight,
                        ...styles.justifyContentBetween,
                      }}
                    >
                      <Text style={{ ...styles.alignSelf }}>
                        {ticketData?.adminDetails[0]?.Address}{" "}
                      </Text>
                      <Text style={{ ...styles.alignSelf }}>
                        {ticketData?.adminDetails[0]?.CityName}{" "}
                        {ticketData?.adminDetails[0]?.StateName}
                      </Text>
                      <Text style={{ ...styles.alignSelf }}>
                        {ticketData?.adminDetails[0]?.CountryName}{" "}
                        {ticketData?.adminDetails[0]?.PostalCode}
                      </Text>
                    </View>
                  ) : null}
                </View>
              </View>
              <View style={styles.innerSection}>
                <View style={styles.borderleft}></View>
                {getPassengerDetails()}
                {getTourinfo()}
                {getTourDetails(tourDetail, index)}
              </View>
            </View>
          </Page>
        )}
      </>
    ));
  };

  return <Document>{generateTicketHTML()}</Document>;
};

export default ActivitiesDoc;
