import React, { useState } from "react";
import { Col, Row, message, Modal, Collapse, Select, Card, Button } from "antd";
import { useAuthContext } from "../../providers/AuthProvider";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { getAllActivitiesPrice } from "../../../pages/Activitespkg/Activitiescheckout/activitiesHelper";
import "../ticketActivities.scss";
import "./TicketScreen.scss";
import QRCode from "qrcode.react";
import ApiClient from "../../../helpers/ApiClient";
import Table from "react-bootstrap/Table";
import { useCurrencyContext } from "../../providers/CurrencyProvider";
import {
  DownloadOutlined,
  FileExcelOutlined,
  MailOutlined,
  MessageOutlined,
  PrinterOutlined,
  DollarOutlined,
  UserOutlined,
  PhoneOutlined,
  QrcodeOutlined,
  IdcardOutlined,
  NumberOutlined,
  DollarCircleFilled,
  DollarTwoTone,
  CheckCircleOutlined,
  BellOutlined,
  CloseCircleOutlined,
  StopOutlined
} from "@ant-design/icons";
const { Panel } = Collapse;
const { Option } = Select;
const { confirm } = Modal;

const NewTicketActivites = ({ ticketData, cmsFareRules, voucherData }) => {
  const {
    isLogin: { agent },
  } = useAuthContext();
  const { totalAmount, discount } = getAllActivitiesPrice(
    ticketData.TourDetails,
    ticketData.convienenceData,
    ticketData.promoData
  );
  const [CancellationPolicyModal, setCancellationPolicyModal] = useState(false);
  const [CancellationPolicy, setCancellationPolicy] = useState([]);
  const [QrCodeIndex, setQrCodeIndex] = useState(0);
  const [QrCodeDetails, setQrCodeDetails] = useState({ tour: {}, tourIndex: 0 });
  const [isVisible, setIsVisible] = useState(false);
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const getStatus = (status) => {
    switch (status) {
      case 2: {
        return <span style={{ color: "#FFA500" }} className="border p-2 rounded">BLOCKED <StopOutlined /> </span>;
      }

      case 1: {
        return (
          <span style={{ color: "#008000" }} className="border p-2 rounded">
            <b> CREATED </b>
          </span>
        );
      }

      case 3: {
        return (
          <span style={{ color: "#008000" }} className="border p-2 rounded">
            <b> CONFIRMED <CheckCircleOutlined /> </b>
          </span>
        );

      }
      case 6: {
        return (
          <span style={{ color: "#FF0000" }} className="border p-2 rounded">
            <b> CANCELED <CloseCircleOutlined /></b>
          </span>
        );
      }

      case 11: {
        return (
          <span style={{ color: "#FF0000" }} className="border p-2 rounded">
            <b> VOUCHERED <QrcodeOutlined /> </b>
          </span>
        );
      }
      case 7: {
        return (
          <span style={{ color: "#FF0000" }} className="border p-2 rounded">
            <b> On Request <BellOutlined /> </b>
          </span>
        );
      }

      default:
        return;
    }
  };

  const getPaxCount = (pax) => {
    return `Adult(s) ${pax?.adults !== "0" ? pax?.adults : 0} ${pax?.childCount !== "0" ? ", Child(s) " + pax?.childCount : " "
      } ${pax?.infantCount !== "0" ? ", Infant(s) " + pax?.infantCount : " "}`;
  };

  const getTotalAmount = () => {
    let postMarkup = ticketData.totalPostMarkup
      ? ticketData.totalPostMarkup
      : 0;
    let totalAmount =
      Number(ticketData.PartPayment.serviceTotal) + Number(postMarkup);

    return Number(totalAmount).toFixed(2);
  };

  const getTicketPolicy = (tour) => {
    let { tourId, journeyDate, startTime, serviceUniqueId, optionId } = tour;
    setCancellationPolicyModal(true)
    setCancellationPolicy(tour?.ticketPolicy)
  }

  const toggleCancelHandler = () => {
    setCancellationPolicyModal((prev) => !prev);
  };

  const getImporatntNote = (status) => {
    switch (status) {
      case 3: {
        return <p className="font-weight-bold m-0"><span className="text-danger">Note :-</span> Don't forget to voucher your transfer. If you do not voucher this transfer, it will be cancelled automatically.</p>;
      }
      case 11: {
        return <p className="font-weight-bold m-0"><span className="text-danger">Note :-</span>Please wait for 30 minutes for the availability of the QR code.If any query contact on <strong>Emergency Number :- 9166868837, 9999442804, 011-49511313</strong>. Thank you for your patience.</p>;
      }
      case 7: {
        return <p className="font-weight-bold m-0"><span className="text-danger">Note :-</span> Your booking is being processed, but confirmation is pending. Our team will contact you shortly.</p>;
      }
      default:
        return;
    }
  };

  const handleOnChange = (val) => {
    setQrCodeIndex(val);
  }

  /**  Comment displayAllQrCode 
  const displayAllQrCode = (tour, key) => {
    confirm({
      title: 'ALL Qr Code Genrated',
      icon: <QrcodeOutlined />,
      content: (
        <React.Fragment>
          <Row>
            <Col md={12} className="my-3" key={key}>
              <Select style={{ width: 300 }} onChange={handleOnChange} defaultValue={QrCodeIndex}>
              {[...Array(tour.ticket.length)].map((_, count) => (
                <Option key={"QrCode" + count} value={count} > {count + 1}</Option>
              ))}
              </Select>
            </Col>
          </Row>
          <Row>
            <Col className="my-3 mx-auto" md={12} key={key}>
              <QRCode
                value={tour.ticket[QrCodeIndex].barcode}
                size={100}
                title={tour.tourName}
                id={"packageQR" + key + "" + QrCodeIndex}
              />
            </Col>
          </Row>
        </React.Fragment>
      ),
      onOk() {
        console.log('OK');
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };*/

  const displayAllQrCode = (tour, key) => {
    setIsVisible(true)
    setQrCodeDetails({ tour: tour, tourIndex: key })
  }

  const handleOkQRModal = () => {
    setIsVisible(false);
  }

  return (
    <div className="activities-ticket">
      <h6 className="font-weight-bold">Activity Tickets</h6>
      <div class="card border" role="alert">
        <div className="pax-info-header mb-3">
          <h6 className="pax-title">Passanger Details</h6>
          <div className="subinfo">
            <div className="user_name mb-2 text-dark d-flex justify-content-between">
              <div className="sub-title">
                <b><UserOutlined />Guest Name : </b>
                <span>
                  {ticketData?.Passengers?.firstName}{" "}
                  {ticketData?.Passengers?.lastName}
                </span>
              </div>
              <div className="sub-title">
                <b><MailOutlined />Email : </b>
                <span>{ticketData?.Passengers?.email}</span>
              </div>
            </div>
            <div className="user_name text-dark d-flex justify-content-between">
              <div className="sub-title">
                <b><PhoneOutlined rotate={100} />Contact : </b>
                <span>{ticketData?.Passengers.mobile}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="pax-info-header">
          <h6 className="pax-title">Transfer Details</h6>
          <div className="subinfo">
            <div className="user_name mb-2 text-dark d-flex justify-content-between">
              <div className="sub-title">
                <b><NumberOutlined />Booking ID : </b>
                <span>SYT-A80148471</span>
              </div>
              <div className="sub-title">
                <b><DollarOutlined /> Total Amount : </b>
                <span> {activeCurrency} {currencyValue(totalAmount)}</span>
              </div>
            </div>
            <div className="user_name text-dark d-flex justify-content-between">
              <div className="sub-title">
                <b>Final Amount : </b>
              </div>
              <div className="sub-title">
                <span>{activeCurrency} {currencyValue(getTotalAmount())}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {ticketData?.TourDetails?.map((tour, index) => (
        <div class="card border" role="alert">
          <div className="ticket-details">
            <div className="ticket_header d-flex justify-content-between">
              <h6 className="pax-title">{tour?.tourName}</h6>
              <h6 className="pax-title">{moment(tour.journeyDate, "YYYY-MM-DD").format("DD MMM YYYY")}</h6>
            </div>
            <Row className="justify-content-between my-2">
              <Col md={8} xs={24}>
                <img style={{ objectFit: "cover", height: "130px" }} className="hotel-tag-1" src={tour.tourImage} alt="First slide" />
              </Col>
              {tour.hasOwnProperty('ticket') && tour.ticket.length == 1 ? (
                <Col md={4} xs={24} className="">
                  {tour.hasOwnProperty('ticket') && tour.ticket.length > 0 && (
                    <React.Fragment>
                      {tour.ticket.map((x, i) => (
                        <QRCode
                          value={x.barcode}
                          size={100}
                          title={tour.tourName}
                          id={"packageQR" + index + "" + i}
                        />
                      ))}
                    </React.Fragment>
                  )}
                </Col>
              ) : tour.hasOwnProperty('ticket') && tour.ticket.length > 1 ? (
                <Col md={5} xs={24} style={{ position: 'relative', textAlign: 'center' }}>
                  <Button onClick={() => displayAllQrCode(tour, index)} style={{ position: 'absolute', bottom: '0%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '14px', color: 'black', textShadow: '1px 1px 2px rgba(248, 248, 247, 0.2)' }}>Show All QR Code </Button>
                </Col>
              ) : (
                <Col md={5} xs={24} style={{ position: 'relative', textAlign: 'center' }}>
                  <QrcodeOutlined style={{ fontSize: '140px', filter: 'blur(3px)', opacity: '0.2' }} />
                  <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '14px', color: 'black', textShadow: '1px 1px 2px rgba(248, 248, 247, 0.2)' }}>QR Code not Generated</span>
                </Col>
              )}
            </Row>
            {([3, 11, 7].includes(tour.bookingStatus) && (!tour.hasOwnProperty('ticket') || tour.ticket.length == 0)) && (
              <div class="alert" role="alert" style={{ background: "linear-gradient(6deg, #f1e67b, transparent)" }}>
                {getImporatntNote(tour.bookingStatus)}
              </div>
            )}
            {tour.tourShortDescription != '' && (
              <div class="alert alert-info" role="alert">
                <p className="hotel-list-para"
                  dangerouslySetInnerHTML={{
                    __html: tour.tourShortDescription,
                  }}></p>
              </div>
            )}
            <div className="ticket-info-lowerpart">
              <div className="ticket-info mb-2 d-flex justify-content-between">
                <div className="title">
                  Booking Status :
                </div>
                <div className="title">
                  {getStatus(tour.bookingStatus)}
                </div>
              </div>
              <div className="ticket-info mb-2 d-flex justify-content-between">
                <div className="title">
                  Tour Date :
                </div>
                <div className="title">
                  {moment(tour.journeyDate, "YYYY-MM-DD").format("DD MMM YYYY")}
                </div>
              </div>
              <div className="ticket-info mb-2 d-flex justify-content-between">
                <div className="title">
                  Transfer Option :
                </div>
                <div className="title">
                  {tour.transferName}
                </div>
              </div>
              {tour.duration != "" && (
                <div className="ticket-info mb-2 d-flex justify-content-between">
                  <div className="title">
                    Duration :
                  </div>
                  <div className="title">
                    {tour.duration}
                  </div>
                </div>
              )}
              <div className="ticket-info mb-2 d-flex justify-content-between">
                <div className="title">
                  Start Time :
                </div>
                <div className="title">
                  {tour.startTime}
                </div>
              </div>
              <div className="ticket-info mb-2 d-flex justify-content-between">
                <div className="title">
                  Number Of Passengers :
                </div>
                <div className="title">
                  {getPaxCount(tour.pax)}
                </div>
              </div>
              <div className="ticket-info mb-2 d-flex justify-content-between">
                <div className="title">
                  Amount :
                </div>
                <div className="title">
                  {activeCurrency} {currencyValue(Number(tour.serviceTotal).toFixed(2))}
                </div>
              </div>
              <div className="ticket-info mb-2 d-flex justify-content-between">
                <div className="title">
                  Policy :
                </div>
                <div className="title">
                  <p className="wt-activity2 mt-3 btn-link" onClick={() => getTicketPolicy(tour)} style={{ cursor: "pointer", textDecoration: "none", color: "#0056b3" }}>View Cancellation Policy</p>
                </div>
              </div>
            </div>
            {/* <div className="qr-code row justify-content-between container-fluid">
              {tour.hasOwnProperty('ticket') && tour.ticket.length > 0 && (
                <React.Fragment>
                  {tour.ticket.map((x, i) => (
                    <QRCode className="col-md-3 m-3"
                      value={x.barcode}
                      size={100}
                      title={tour.tourName}
                      id={"packageQR" + index + "" + i}
                    />
                  ))}
                </React.Fragment>
              )}
            </div> */}
          </div>
        </div>
      ))}
      {/* Canelation Policy Modal */}
      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Tour Cancellation Policy</strong>
            </h6>
          </div>,
        ]}
        centered
        width={1000}
        visible={CancellationPolicyModal}
        onCancel={toggleCancelHandler}
        footer={[]}
      >
        <Collapse defaultActiveKey={["1"]}>
          <Panel header="Booking Policy" key="1">
            <h5>Cancellation Policy</h5>
            <p>{CancellationPolicy[0]?.cancellationPolicy} {CancellationPolicy.length == 0 && "To obtain information regarding our cancellation policy, kindly reach out to our support team"}</p>
            <pre>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    CancellationPolicy[0]?.cancellationPolicyDescription,
                }}
              ></div>
            </pre>
            <h5>Child Policy</h5>
            <pre>
              <div
                dangerouslySetInnerHTML={{
                  __html: CancellationPolicy[0]?.childPolicyDescription,
                }}
              ></div>
            </pre>
          </Panel>
        </Collapse>
      </Modal>
      {/* Dynamic QR Code Modal */}
      <Modal title={<span>ALL QR Code Generated <QrcodeOutlined /></span>} visible={isVisible} onOk={handleOkQRModal} onCancel={handleOkQRModal}>
        <React.Fragment>
          <Row>
            <Col md={24} className="my-3">
              <Select style={{ width: 300 }} onChange={handleOnChange} defaultValue={QrCodeIndex}>
                {[...Array((Object.keys(QrCodeDetails.tour).length > 0 || QrCodeDetails.tour.hasOwnProperty('ticket').length> 0) ? QrCodeDetails?.tour?.ticket.length : 0)].map((_, count) => (
                  <Option key={"QrCode" + count} value={count}> {count + 1}</Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row>
            <Col className="my-3 mx-auto" md={12}>
              <QRCode
                value={(Object.keys(QrCodeDetails.tour).length > 0 || QrCodeDetails.tour.hasOwnProperty('ticket').length> 0) ? QrCodeDetails?.tour?.ticket[QrCodeIndex]?.barcode : ''}
                size={100}
                title={QrCodeDetails?.tour?.tourName}
                id={"packageQR" + QrCodeDetails.tourIndex + "" + QrCodeIndex}
              />
            </Col>
          </Row>
        </React.Fragment>
      </Modal>
    </div>
  )
};

export default NewTicketActivites;
